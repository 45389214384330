.custom-dropdown {
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 14px;
    transition: background-color 0.3s, border-color 0.3s;
}

.custom-dropdown:hover {
    background-color: #eaeaea;
}

.custom-dropdown:focus {
    border-color: #ff5002;
    outline: none;
    box-shadow: 0 0 5px rgba(255, 80, 2, 0.5);
}
