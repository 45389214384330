@keyframes upload-icon-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.animate-upload-icon {
    animation: upload-icon-animation 1s infinite;
}

.spinner-ring {
    width: 50px;
    cursor: none;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    animation: spin 2s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-ring::before {
    content: "";
    cursor: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top: 6px solid #ff5002; /* Color the top portion of the circle */
    animation: rotateSpinner 2s linear infinite;
}

/* Keyframes for spinner rotation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* This rotates the `::before` element, creating the spinning effect */
@keyframes rotateSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}